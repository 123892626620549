@import "main.css";
h2, h3 {color : #15954a !important;
}

.ctaLinks-button { display : none !important;
}

.cta_banner > div > div > p{ font-size: 15px !important;
}

.owl-carousel .owl-item img {
    width: 50% !important;
    margin: 0 auto !important;
}

.text-decoration-none{ color: white !important;
}

.avatar-google-reviews{ 
display : none !important;
}